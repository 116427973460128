@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../fonts/SpaceGrotesk-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/SpaceGrotesk-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/SpaceGrotesk-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../fonts/SpaceGrotesk-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/SpaceGrotesk-Bold.ttf) format('truetype');
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}

body {
    font-family: SpaceGrotesk, sans-serif;
    background: #000;
    color: #fffc;
    font-size: 18px;
    line-height: 28px;
    overflow: hidden;
    background-color: #1d2141;
    background-position: 0 0;
    background-size: auto;
    &.home-page {
        background-image: url(../img/body_bg.png);
    }
    &.mint-page {
        background-image: url(../img/body_bg.png);
    }
    @media (max-width: 767px) {
        font-size: 16px;
        overflow-y: hidden;
    }
}

// body::-webkit-scrollbar {
// 	width: 10px;
// }

// body::-webkit-scrollbar-track {
// 	background: #c6c3be;
// }

// body::-webkit-scrollbar-thumb {
// 	background: #474747;
// }

.mt-40 {
    margin-top: 40px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.container {
    display: block;
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px;
    opacity: 1;
    @media screen and (max-width: 768px) {
        padding: 0px 15px;
    }
}

.wallet-connect {
    display: block;
    border-radius: 12px;
    background-color: #ff0420;
    padding: 5px 25px;
    font-size: 17px;
    text-align: center;
    margin: 12px auto 2px;
    border-color: #fff;
}

.view-tx {
    display: block;
    border-radius: 12px;
    background-color: lightskyblue;
    padding: 5px 25px;
    font-size: 17px;
    text-align: center;
    margin: 12px auto 2px;
    border-color: #fff;
}

.section-title {
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
    font-size: 46px;
    line-height: 52px;
    font-weight: 700;
    .text-accent {
        color: #ffe401;
    }
    @media screen and (max-width: 991px) {
        font-size: 30px;
        line-height: 34px;
    }
}

.nav-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    .navbar {
        background-color: transparent;
        border: none;
        margin: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 0;
        left: 0;

        .container {
            .navbar-header {
                .navbar-toggle {
                    background-color: transparent !important;
                    border: none;
                    color: #fff;
                    top: 15px;
                    right: 5px;
                    .icon-bar {
                        background-color: #fff;
                    }
                }
            }

            .navbar-collapse {
                .navbar-nav {
                    li {
                        a {
                            position: relative;
                            display: inline-block;
                            vertical-align: top;
                            text-decoration: none;
                            color: #222;
                            padding: 20px;
                            text-align: left;
                            margin-left: auto;
                            margin-right: auto;

                            color: #fff;
                            font-size: 18px;
                            font-weight: 400;
                            border-bottom: 2px solid transparent;
                            padding: 40px 16px;
                            @media (max-width: 767px) {
                                text-align: center;
                                display: block;
                                padding: 15px 20px;
                            }
                        }

                        &.active {
                            a {
                                background-color: transparent;
                                // border-bottom-color: #4ca3c8;
                            }
                        }
                    }
                }
                @media (max-width: 767px) {
                    background-color: #1f2337;
                    border-color: #1f2337;
                    box-shadow: none;
                    position: relative;
                    top: 25px;
                }
            }
            @media (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.header-container {
    display: flex;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    .video-container {
        position: absolute;
        z-index: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        .bg-video {
            z-index: -1;
            position: absolute;
            object-fit: cover;
            z-index: -1;
            width: 100vw;
            height: 100%;
        }

        &:before {
            content: '';
            width: 100%;
            height: 200px;
            position: absolute;
            top: 0px;
            left: 0;
            z-index: 1;
            background: linear-gradient(180deg, #000, transparent);

            @media (max-width: 767px) {
                height: 50px;
            }
        }

        &:after {
            content: '';
            width: 100%;
            height: 200px;
            position: absolute;
            bottom: 0px;
            left: 0;
            z-index: 1;
            background: linear-gradient(180deg, transparent, #00042d);

            @media (max-width: 767px) {
                height: 50px;
            }
        }
    }
    .container {
        .header-content-container {
            padding: 7% 15px 5%;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .top {
                .logo {
                    max-width: 550px;
                    width: 100%;
                }
                > p {
                    margin-bottom: 0;
                    font-weight: 700;
                    font-size: 20px;
                    text-shadow: 0px 0px 5px #000;
                    color: #fff;
                    @media (max-width: 767px) {
                        font-size: 16px;
                    }
                }
            }
            .btn-container {
                .btns {
                    outline: none !important;
                    padding: 10px;
                    border: 4px solid #fff;
                    border-radius: 50%;
                    background-color: transparent;
                    -webkit-transition: 0.2s;
                    transition: 0.2s;
                    font-size: 18px;
                    line-height: 22px;
                    display: inline-block;
                    color: #fff;
                    margin: 20px 2% 0px 2%;
                    text-decoration: none;
                    cursor: pointer;
                    position: relative;
                    transition: all 0.3s ease-in-out;
                    img {
                        width: 48px;
                        height: 48px;
                        @media (max-width: 767px) {
                            width: 32px;
                            height: 32px;
                        }
                    }
                    &:hover {
                        border-color: #95ffff;
                        box-shadow: 0px 0px 8px 6px #2a4ee7,
                            0px 0px 8px 6px #2a4ee7 inset;
                    }
                    @media (max-width: 767px) {
                        font-size: 14px;
                        padding: 5px;
                        border: 2px solid #fff;
                        margin: 20px 3% 0px 3%;
                    }
                }
                @media (max-width: 767px) {
                    margin-top: 10%;
                }
            }
            @media (max-width: 767px) {
                padding: 25% 15px 20%;
            }
        }
    }
    @media screen and (max-width: 991px) {
        // min-height: 600px;
    }
    @media screen and (max-width: 767px) {
        // min-height: 68vh;
    }
    @media screen and (max-width: 479px) {
        // padding-top: 72px;
    }
}

.about-container {
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
    .container {
        position: relative;
        z-index: 1;
        .text-2-columns {
            -webkit-column-count: 2;
            column-count: 2;
            -webkit-column-gap: 160px;
            column-gap: 160px;
            @media screen and (max-width: 768px) {
                -webkit-column-count: 1;
                column-count: 1;
            }
        }
    }
    &:before {
        content: '';
        width: 100%;
        height: 400px;
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 0;
        background: linear-gradient(180deg, #00042d, transparent);

        @media (max-width: 767px) {
            height: 50px;
        }
    }
}

.on-scroll-slider {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    overflow: hidden;
    width: 240vw;
    margin-left: 0;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    .scroll-slider-item {
        width: 150px;
        margin-right: 50px;
    }
}

.roadmap-container {
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
        .img-container {
            position: relative;
            display: -ms-grid;
            display: grid;
            padding: 56px;
            grid-auto-columns: 1fr;
            grid-column-gap: 80px;
            grid-row-gap: 16px;
            -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
            -ms-grid-rows: auto;
            grid-template-rows: auto;
            border-radius: 25px;
            background-color: #1f2337;
            img {
                width: 100%;
                @media screen and (max-width: 991px) {
                    border-radius: 10px;
                }
            }
            @media screen and (max-width: 991px) {
                padding: 15px;
            }
        }
    }
}

.future-collections-container {
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
        .row {
            .left {
                .img-container {
                    img {
                        width: 100%;
                        max-width: 500px;
                        padding-top: 40px;
                        @media screen and (max-width: 768px) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .right {
                .panel-group {
                    z-index: 1;
                    position: relative;

                    .panel {
                        border-radius: 0px;
                        background: transparent;
                        border: 1px solid #1f2337;
                        margin-bottom: 25px;
                        position: relative;
                        background: #1f2337;
                        border-radius: 10px !important;

                        .panel-heading {
                            color: #fff;
                            background-color: transparent;
                            padding: 0px;

                            .panel-title {
                                a {
                                    font-size: 24px;
                                    font-weight: 400;
                                    position: relative;
                                    display: block;
                                    text-decoration: none;
                                    color: #111;
                                    padding: 32px 40px;
                                    transition: all 0.3s ease-in-out;
                                    color: #fff;

                                    p {
                                        margin: 0;
                                    }

                                    &.collapsed {
                                        p {
                                            background: linear-gradient(
                                                111deg,
                                                rgb(0, 176, 251),
                                                rgb(194, 117, 199)
                                            );
                                            -webkit-background-clip: text;
                                            -webkit-text-fill-color: #0000;
                                            filter: brightness(1.2);
                                        }

                                        span {
                                            color: #1d51fe;
                                        }
                                    }

                                    .glyphicon {
                                        position: absolute;
                                        right: 40px;
                                        top: 32px;
                                        transition: all 0.3s ease;
                                        transform: rotate(0deg);
                                        color: #1d51fe;
                                        transform: rotate(180deg);

                                        @media (max-width: 767px) {
                                            right: 15px;
                                            top: 10px;
                                        }
                                    }

                                    @media (max-width: 767px) {
                                        padding: 10px 15px;
                                        font-size: 14px;
                                    }

                                    &.collapsed {
                                        .glyphicon {
                                            transform: rotate(0deg);
                                        }
                                    }
                                }
                            }
                        }

                        .panel-collapse {
                            .panel-body {
                                border: none;
                                padding: 0px 40px 32px;

                                p {
                                    font-size: 18px;
                                    font-weight: 400;

                                    @media (max-width: 767px) {
                                        font-size: 14px;
                                    }
                                }
                                img {
                                    max-width: 100%;
                                    border-radius: 7px;
                                    @media (min-width: 768px) {
                                        max-height: 45vh;
                                    }
                                }
                            }
                        }

                        &:hover {
                            border-color: #1d51fe;
                            background-color: #1d51fe33;
                        }
                    }
                }
            }
        }
    }
}

.peeps-container {
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
        .title-nav-container {
            display: flex;
            justify-content: space-between;
            .nav-buttons-container {
                display: flex;
                justify-content: center;
                .slider-nav {
                    display: flex;
                    justify-content: center;
                    width: 48px;
                    height: 48px;
                    align-items: center;
                    border: 2px solid #1d51fe;
                    border-radius: 50%;
                    &.left {
                        margin-right: 15px;
                    }
                    &.right {
                        transform: rotate(180deg);
                    }
                    &:hover {
                        background-color: #1d51fe33;
                    }
                }
            }
        }
        .owl-nav {
            display: none;
        }
    }
}

.map-container {
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
        .img-container {
            position: relative;
            display: -ms-grid;
            display: grid;
            padding: 56px;
            grid-auto-columns: 1fr;
            grid-column-gap: 80px;
            grid-row-gap: 16px;
            -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
            -ms-grid-rows: auto;
            grid-template-rows: auto;
            border-radius: 25px;
            background-color: #1f2337;
            text-align: center;

            img {
                width: 100%;

                @media screen and (max-width: 991px) {
                    border-radius: 20px;
                }
                @media screen and (max-width: 768px) {
                    border-radius: 15px;
                }
            }
            @media screen and (max-width: 768px) {
                padding: 15px;
            }
        }
    }
}

.team-container {
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
        .row {
            margin-top: 120px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .col-md-4 {
                position: relative;
                .inner {
                    margin-top: 60px;
                    margin-bottom: 60px;
                    position: relative;
                    text-align: center;
                    padding: 24px;
                    border-radius: 20px;
                    background-color: #1f2337;
                    a {
                        display: inline-block;
                        transform: translateY(-50%);
                        margin-top: -24px;
                        img {
                            width: 120px;
                            height: 120px;
                        }
                    }
                    h3 {
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 500;
                        color: #ffe401;
                        margin-top: 0;
                        margin-bottom: 8px;
                    }
                    p {
                        color: #fffc;
                        font-size: 18px;
                        line-height: 28px;
                        min-height: 60px;
                    }
                }
                @media (max-width: 767px) {
                    width: 50%;
                }
                @media (max-width: 550px) {
                    width: 100%;
                }
            }
        }
    }
}

.community-container {
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;
    .container {
        .inner {
            position: relative;
            border-radius: 25px;
            background-color: #1f2337;
            padding: 56px;
            .section-title {
                margin-bottom: 24px;
                span {
                    display: block;
                }
            }
            .links-container {
                .btns {
                    outline: none !important;
                    padding: 10px;
                    border: 4px solid #fff;
                    border-radius: 50%;
                    background-color: transparent;
                    -webkit-transition: 0.2s;
                    transition: 0.2s;
                    font-size: 18px;
                    line-height: 22px;
                    display: inline-block;
                    color: #fff;
                    margin: 20px 2% 0px 2%;
                    text-decoration: none;
                    cursor: pointer;
                    position: relative;
                    transition: all 0.3s ease-in-out;
                    width: 76px;
                    img {
                        width: 48px;
                        height: 48px;

                        @media (max-width: 767px) {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    &:hover {
                        border-color: #95ffff;
                        box-shadow: 0px 0px 8px 6px #2a4ee7,
                            0px 0px 8px 6px #2a4ee7 inset;
                    }

                    @media (max-width: 767px) {
                        width: 46px;
                        font-size: 14px;
                        padding: 5px;
                        border: 2px solid #fff;
                        margin: 20px 3% 0px 3%;
                    }
                }
                @media screen and (max-width: 768px) {
                    grid-template-columns: 1fr 1fr;
                }
            }
            .imgs {
                position: relative;
                .img-item1 {
                    position: absolute;
                    left: auto;
                    top: 10%;
                    right: 25%;
                    bottom: 0;
                    width: 280px;
                    height: 280px;
                    margin-top: -113px;
                    margin-bottom: -13px;
                    padding-bottom: 0;
                    @media screen and (min-width: 1920px) {
                        right: 30%;
                    }
                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
                .img-item2 {
                    position: absolute;
                    left: auto;
                    top: 0;
                    right: 7%;
                    bottom: auto;
                    width: 150px;
                    height: 150px;
                    margin-top: -139px;
                    margin-right: 33px;
                    @media screen and (min-width: 1920px) {
                        right: 15%;
                    }
                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                padding: 30px;
            }
        }
        @media screen and (max-width: 768px) {
            padding: 0 15px;
        }
    }
}

.story-container {
    padding-top: 60px;
    padding-bottom: 60px;

    .container {
        .row {
            .left {
                .section-title {
                    span {
                        display: block;
                    }
                }
                .img-container {
                    img {
                        width: 100%;
                        max-width: 500px;
                        @media screen and (max-width: 768px) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .right {
                .panel-group {
                    z-index: 1;
                    position: relative;

                    .panel {
                        border-radius: 0px;
                        background: transparent;
                        border: 1px solid #1f2337;
                        margin-bottom: 25px;
                        position: relative;
                        background: #1f2337;
                        border-radius: 10px !important;

                        .panel-heading {
                            color: #fff;
                            background-color: transparent;
                            padding: 0px;

                            .panel-title {
                                a {
                                    font-size: 24px;
                                    font-weight: 400;
                                    position: relative;
                                    display: block;
                                    text-decoration: none;
                                    color: #111;
                                    padding: 32px 40px;
                                    transition: all 0.3s ease-in-out;
                                    color: #fff;

                                    p {
                                        margin: 0;
                                    }

                                    &.collapsed {
                                        p {
                                            background: linear-gradient(
                                                111deg,
                                                rgb(0, 176, 251),
                                                rgb(194, 117, 199)
                                            );
                                            -webkit-background-clip: text;
                                            -webkit-text-fill-color: #0000;
                                            filter: brightness(1.2);
                                        }

                                        span {
                                            color: #1d51fe;
                                        }
                                    }

                                    .glyphicon {
                                        position: absolute;
                                        right: 40px;
                                        top: 32px;
                                        transition: all 0.3s ease;
                                        transform: rotate(0deg);
                                        color: #1d51fe;
                                        transform: rotate(180deg);

                                        @media (max-width: 767px) {
                                            right: 15px;
                                            top: 10px;
                                        }
                                    }

                                    @media (max-width: 767px) {
                                        padding: 10px 15px;
                                        font-size: 14px;
                                    }

                                    &.collapsed {
                                        .glyphicon {
                                            transform: rotate(0deg);
                                        }
                                    }
                                }
                            }
                        }

                        .panel-collapse {
                            .panel-body {
                                border: none;
                                padding: 0px 40px 32px;

                                p {
                                    font-size: 18px;
                                    font-weight: 400;

                                    @media (max-width: 767px) {
                                        font-size: 14px;
                                    }
                                }

                                iframe {
                                    width: 100%;
                                    min-height: 500px;
                                }
                            }
                        }

                        &:hover {
                            border-color: #1d51fe;
                            background-color: #1d51fe33;
                        }
                    }
                }
            }
        }
    }
}

.collaborations-container {
    padding-top: 60px;
    padding-bottom: 60px;

    .container {
        .img-container {
            position: relative;
            display: -ms-grid;
            display: grid;
            padding: 56px;
            grid-auto-columns: 1fr;
            grid-column-gap: 80px;
            grid-row-gap: 16px;
            -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
            -ms-grid-rows: auto;
            grid-template-rows: auto;
            border-radius: 25px;
            background-color: #1f2337;
            text-align: center;

            img {
                width: 100%;

                @media screen and (max-width: 991px) {
                    border-radius: 20px;
                }

                @media screen and (max-width: 768px) {
                    border-radius: 15px;
                }
            }

            @media screen and (max-width: 768px) {
                padding: 15px;
            }
        }
    }
}

.map-container {
    padding-top: 60px;
    padding-bottom: 60px;

    .container {
        .row {
            .col-sm-6 {
                text-align: center;
                img {
                    width: 100%;
                    max-height: 150px;
                    max-width: 250px;
                    margin: auto;
                }
            }
        }
    }
}

footer {
    padding-top: 90px;
    padding-bottom: 30px;
    position: relative;
    z-index: 1;
    .container {
        .grid {
            display: grid;
            grid-row-gap: 16px;
            grid-template-columns: 1fr 1fr 1fr;
            .left {
                @media (max-width: 767px) {
                    display: none;
                }
            }
            .center {
                text-align: center;
                a {
                    display: block;
                    margin-top: 8px;
                    margin-right: 16px;
                    margin-left: 16px;
                    -webkit-transition: 0.2s;
                    transition: 0.2s;
                    color: #fff;
                    text-decoration: none;
                }
            }
            .right {
                text-align: right;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                .social-icon {
                    display: -ms-flexbox;
                    display: flex;
                    padding: 8px;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    border: 2px solid #1d51fe;
                    border-radius: 100%;
                    -webkit-transition: 0.2s;
                    transition: 0.2s;
                    text-decoration: none;
                    &:not(:first-child) {
                        margin-left: 16px;
                    }
                    img {
                        width: 32px;
                    }
                }
                @media (max-width: 767px) {
                    text-align: center;
                    justify-content: center;
                }
            }
            @media (max-width: 767px) {
                grid-template-columns: 1fr;
                text-align: center;
            }
        }
        .reserved {
            margin-top: 32px;
            p {
                color: #fffc;
                font-size: 18px;
                line-height: 28px;
                @media (max-width: 767px) {
                    font-size: 12px;
                    text-align: center;
                }
            }
        }
    }
}

.contact-page {
    background-color: #16192c;
    .contact-container {
        // min-height: calc(100vh - 344px);
        overflow: hidden;
        min-height: 100vh;
        // margin-top: 100px;
        padding: 60px 15px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .container {
            .inner {
                text-align: center;
                width: 100%;
                max-width: 600px;
                margin: auto;
                .title {
                    font-size: 72px;
                    font-weight: 700;
                    line-height: 74px;
                    color: #fff;
                    margin-bottom: 32px;
                }
                .para {
                    font-size: 22px;
                    margin-bottom: 32px;
                }
                .success {
                    padding: 15px 15px;
                    border-radius: 10px;
                    background: #56b15a;
                    margin: 100px 0;

                    label {
                        font-weight: 400;
                        text-align: center;
                        margin: 0;
                    }
                }
                form {
                    text-align: left;
                    .input-block {
                        label {
                            display: block;
                            margin-bottom: 16px;
                            font-weight: 300;
                        }
                        input,
                        textarea {
                            width: 100%;
                            display: block;
                            font-size: 14px;
                            line-height: 1.42857143;
                            margin-bottom: 32px;
                            padding: 20px 32px;
                            border: 1px solid #0000;
                            border-radius: 10px;
                            background-color: #1f2337;
                            color: #a5a5a5;
                            &:focus-visible {
                                outline: none;
                            }
                        }
                        &.error {
                            padding: 15px 15px;
                            border-radius: 10px;
                            background: #f44336;
                            margin-bottom: 32px;
                            label {
                                font-weight: 400;
                                text-align: center;
                                margin: 0;
                            }
                        }
                    }
                    .text-center {
                        .btn {
                            padding: 20px 40px;
                            border: 1px solid #1d51fe;
                            border-radius: 40px;
                            background-color: #1d51fe;
                            color: #fff;
                            -webkit-transition: 0.2s;
                            transition: 0.2s;
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                }
            }
            .bg {
                position: relative;
                display: block;
                bottom: -18vh;
                @media screen and (min-width: 1920px) {
                    bottom: -10vh vh;
                }
                @media screen and (max-width: 992px) {
                    display: none;
                }
                .contact_bg {
                    position: absolute;
                    z-index: -1;
                    max-height: 208px;
                    max-width: 208px;
                    .contact_bg_img {
                        width: auto;
                        margin-left: 0;
                        @media screen and (min-width: 1920px) {
                            display: block;
                            min-width: 150px;
                            opacity: 1;
                        }
                    }
                    &.contact_bg_01 {
                        left: 0;
                        top: 0;
                        right: auto;
                        bottom: auto;
                        width: 280px;
                        height: 280px;
                        max-height: 350px;
                        max-width: 350px;
                        margin-top: -14.6vh;
                        margin-bottom: 6px;
                        margin-left: 35px;
                        transform: translate3d(0px, -50px, 0px) scale3d(1, 1, 1)
                            rotateX(0deg) rotateY(0deg) rotateZ(-15deg)
                            skew(0deg, 0deg);
                        opacity: 1;
                        transform-style: preserve-3d;
                        @media screen and (min-width: 1280px) {
                            width: 300px;
                            height: 300px;
                            max-height: 350px;
                            max-width: 350px;
                            margin-top: -22vh;
                            margin-left: 60px;
                        }
                        @media screen and (min-width: 1440px) {
                            width: 300px;
                            height: 300px;
                            max-height: 350px;
                            max-width: 350px;
                            margin-left: 100px;
                        }
                        @media screen and (min-width: 1920px) {
                            width: 300px;
                            height: 300px;
                            max-height: 350px;
                            max-width: 350px;
                            margin-top: -18vh;
                            margin-left: 200px;
                            padding-top: 0;
                            opacity: 1;
                        }
                    }
                    &.contact_bg_02 {
                        left: 0;
                        top: auto;
                        right: auto;
                        bottom: 0;
                        max-height: 250px;
                        max-width: 250px;
                        min-width: 0;
                        margin-bottom: -72vh;
                        margin-left: 3px;
                        padding-left: 0;
                        opacity: 1;
                        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                            rotateX(0deg) rotateY(0deg) rotateZ(-15deg)
                            skew(0deg, 0deg);
                        transform-style: preserve-3d;
                        @media screen and (min-width: 1280px) {
                            margin-bottom: -63vh;
                            margin-left: 210px;
                        }
                        @media screen and (min-width: 1440px) {
                            margin-bottom: -63vh;
                            margin-left: 210px;
                        }
                        @media screen and (min-width: 1920px) {
                            margin-bottom: -45vh;
                            margin-left: 210px;
                        }
                    }
                    &.contact_bg_03 {
                        left: 0;
                        top: auto;
                        right: auto;
                        bottom: 0;
                        margin-bottom: -29vh;
                        margin-left: -20px;
                        padding-left: 0;
                        transform: translate3d(0px, 50px, 0px) scale3d(1, 1, 1)
                            rotateX(0deg) rotateY(0deg) rotateZ(-15deg)
                            skew(0deg, 0deg);
                        opacity: 1;
                        transform-style: preserve-3d;
                        @media screen and (min-width: 1920px) {
                            margin-right: -200px;
                            margin-left: 317px;
                            padding-right: 0;
                        }
                    }
                    &.contact_bg_04 {
                        left: auto;
                        top: 0;
                        right: 0;
                        bottom: auto;
                        margin-top: 16vh;
                        margin-right: -32px;
                        padding-right: 22px;
                        transform: translate3d(0px, -50px, 0px) scale3d(1, 1, 1)
                            rotateX(0deg) rotateY(0deg) rotateZ(15deg)
                            skew(0deg, 0deg);
                        opacity: 1;
                        transform-style: preserve-3d;
                    }
                    &.contact_bg_05 {
                        left: auto;
                        top: 0;
                        right: 0;
                        bottom: auto;
                        margin-top: -16vh;
                        margin-right: 84px;
                        opacity: 1;
                        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                            rotateX(0deg) rotateY(0deg) rotateZ(15deg)
                            skew(0deg, 0deg);
                        transform-style: preserve-3d;
                        @media screen and (min-width: 1920px) {
                            margin-right: 200px;
                        }
                    }
                    &.contact_bg_06 {
                        left: auto;
                        top: auto;
                        right: 0;
                        bottom: 0;
                        max-height: 300px;
                        max-width: 300px;
                        min-width: 300px;
                        margin-right: 86px;
                        margin-bottom: -62vh;
                        transform: translate3d(0px, 50px, 0px) scale3d(1, 1, 1)
                            rotateX(0deg) rotateY(0deg) rotateZ(15deg)
                            skew(0deg, 0deg);
                        opacity: 1;
                        transform-style: preserve-3d;
                        @media screen and (min-width: 1920px) {
                            max-height: 350px;
                            max-width: 350px;
                            margin-right: 250px;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@keyframes mintoverlay {
    0% {
        background-color: rgba(0, 0, 0, 0.55);
    }
    20% {
        background-color: rgba(0, 0, 0, 0.75);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.35);
    }
    65% {
        background-color: rgba(0, 0, 0, 0.65);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.55);
    }
}

@keyframes ufo {
    0% {
        top: 0px;
        left: 0px;
        transform: rotate(0deg);
    }

    10% {
        top: 50px;
        left: -20vw;
        transform: rotate(10deg);
    }

    20% {
        top: -70px;
        left: -40vw;
        transform: rotate(-10deg);
    }

    40% {
        top: -20px;
        left: -10vw;
        transform: rotate(-10deg);
    }

    60% {
        top: 10px;
        left: 10vw;
        transform: rotate(0deg);
    }

    80% {
        top: -50px;
        left: 15vw;
        transform: rotate(10deg);
    }

    100% {
        top: 0px;
        left: 0px;
        transform: rotate(0deg);
    }
}

.mint-page {
    position: relative;
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.55);
        transition: all 0.3s ease-in-out;
        animation: mintoverlay 10s ease infinite;
        z-index: 0;
    }
    .ufo-container {
        position: absolute;
        max-width: 150px;
        top: 50%;
        right: 30%; 
        z-index: 1;

        .ufo {
            width: 100%;
            position: relative;
            animation: ufo 15s infinite;
            animation-timing-function: linear;
        }

        @media (max-width: 767px) {
            max-width: 50px;
        }
    }
    .mint-container {
        padding-top: 102px;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .coming-soon-container {
            width: 100%;
            max-width: 600px;
            padding-top: 7vh;
            margin: auto;
            .inner{
                position: relative;
                color: #fff;
                border-radius: 15px;
                padding: 30px;
                z-index: 1;
                text-align: center;
                .coming-soon-inner-container {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    max-width: 600px;
    
                    .timer-item {
                        margin: 0px;
                        padding: 0px 10px;
                        text-align: center;
                        width: 25%;
    
                        .timer-item-inner {
                            border-radius: 15px;
                            padding: 20px 10px;
                            border: 1px solid #1d51fe;
                            background-color: rgba(29, 81, 254, 0.2);
                            -webkit-backdrop-filter: blur(5px);
                            backdrop-filter: blur(5px);
    
                            span {
                                display: block;
                                line-height: 1;
    
                                &.number {
                                    font-size: 50px;
                                    font-weight: 500;
                                    min-height: 55px;
                                    position: relative;
                                    margin-bottom: 10px;
    
                                    &:after {
                                        content: "";
                                        width: 80%;
                                        height: 1px;
                                        background: #8f8f8f;
                                        position: absolute;
                                        top: 55px;
                                        left: 10%;
                                    }
    
                                    @media (max-width: 767px) {
                                        font-size: 30px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
    
                                &.label {
                                    padding: 0px;
                                    font-weight: 200;
                                    font-size: 16px;
    
                                    @media (max-width: 767px) {
                                        font-size: 14px;
                                    }
                                }
                            }
    
                            @media (max-width: 767px) {
                                padding: 10px 10px;
                            }
                        }
    
                        @media (max-width: 767px) {
                            padding: 10px 10px;
                            min-width: 100px;
                        }
                    }
    
                    @media (max-width: 767px) {
                        // max-width: 265px;
                    }
                }
                @media (max-width: 767px) {
                    padding: 30px 0;
                }
            }
        }
        .mint-box-container {
            width: 100%;
            max-width: 600px;
            padding-top: 7vh;
            margin: auto;
            .mint-box {
                position: relative;
                color: #fff;
                border-radius: 15px;
                padding: 30px;
                z-index: 1;
                border: 1px solid #1d51fe;
                background-color: rgba(29, 81, 254, 0.2);
                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
                text-align: center;

                p {
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.7);

                    @media (max-width: 767px) {
                        font-size: 13px;
                    }
                }

                .title-container {
                    position: relative;
                    text-align: left;
                    margin-bottom: 35px;
                    padding-bottom: 25px;
                    border-bottom: 1px solid #ccc;

                    h2 {
                        position: relative;
                        text-align: center;
                        margin-top: 0px;
                        margin-bottom: 15px;
                        font-size: 40px;
                        font-weight: 700;

                        @media (max-width: 767px) {
                            font-size: 22px;
                        }
                    }

                    p {
                        font-size: 18px;
                        text-align: center;
                    }
                }

                .info-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 30px;

                    .img-container {
                        width: 105px;

                        img {
                            width: 100%;
                            border-radius: 10px;
                        }

                        @media (max-width: 767px) {
                            width: 80px;
                            margin-right: 15px;
                        }
                    }

                    .inner-info-container {
                        text-align: right;

                        p {
                            position: relative;
                            margin-bottom: 0px;

                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
                        }

                        h3 {
                            margin-top: 0px;
                            margin-bottom: 5px;
                            position: relative;
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                }

                .count {
                    font-size: 24px;
                    font-weight: 600;
                    text-align: center;

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }

                .mint-counter {
                    position: relative;
                    margin-bottom: 30px;
                    background: #f0f0f0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    border-radius: 10px;
                    border-radius: 10px;
                    background-color: rgba(31, 35, 55, 0.8);

                    .counter {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        justify-content: space-evenly;

                        .btn {
                            outline: none;
                            width: 50px;
                            height: 40px;
                            font-size: 20px;
                            font-weight: 900;
                            color: #fff;
                            background: transparent;
                            border: none;
                        }

                        input {
                            outline: 0;
                            color: #fff;
                            height: 40px;
                            width: 50px;
                            font-size: 18px;
                            font-weight: 500;
                            margin: 0px 5px;
                            padding: 0px 5px;
                            text-align: center;
                            border: none;
                            background: transparent;
                        }
                    }

                    h3 {
                        text-align: right;
                        font-size: 20px;
                        font-weight: 500;
                        margin: 0px;

                        @media (max-width: 767px) {
                            font-size: 20px;
                        }
                    }
                }

                .total-container {
                    position: relative;
                    margin-bottom: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        position: relative;
                    }

                    h3 {
                        position: relative;
                        font-size: 20px;
                        font-weight: 500;
                        margin: 0px;

                        @media (max-width: 767px) {
                            font-size: 20px;
                        }
                    }
                }

                > p {
                    position: relative;
                    text-align: center;
                    a {
                        text-decoration: underline;
                    }
                }

                .warning-container {
                    img {
                        max-width: 70px;
                        filter: contrast(0) brightness(1.5);
                    }
                }

                .btn-mint {
                    outline: 0;
                    color: #fff;
                    font-weight: 500;
                    font-size: 22px;
                    letter-spacing: 1px;
                    border: none;
                    // width: 100%;
                    margin: 0px 0px 20px 0px;
                    position: relative;
                    overflow: hidden;

                    padding: 20px 40px;
                    border: 1px solid #1d51fe;
                    border-radius: 40px;
                    background-color: #1d51fe;

                    span {
                        color: #fff;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: -130%;
                        left: -210%;
                        width: 200%;
                        height: 300%;
                        opacity: 0;
                        transform: skew(-40deg);
                        background: rgba(255, 255, 255, 0.13);
                        background: linear-gradient(
                            to right,
                            rgba(255, 255, 255, 0) 0%,
                            rgba(255, 255, 255, 0.13) 77%,
                            rgba(255, 255, 255, 0.5) 92%,
                            rgba(255, 255, 255, 0) 100%
                        );
                    }

                    &:hover:after {
                        opacity: 1;
                        /* top: 0%; */
                        left: 30%;
                        transition-property: left, top, opacity;
                        transition-duration: 0.7s, 0.7s, 0.15s;
                        transition-timing-function: ease;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            padding-top: 50px;
        }
        @media (min-width: 767px) {
            min-height: calc(100vh - 242px);
        }
    }
}

.wallet-check-page {
    background-color: #16192c;
    .ufo-container {
        position: absolute;
        max-width: 150px;
        top: 50%;
        right: 30%;
        z-index: 1;

        .ufo {
            width: 100%;
            position: relative;
            animation: ufo 15s infinite;
            animation-timing-function: linear;
        }

        @media (max-width: 767px) {
            max-width: 50px;
        }
    }
    .wallet-check-container {
        min-height: calc(100vh - 245px);
        position: relative;
        z-index: 2;
        overflow: hidden;
        // margin-top: 100px;
        padding: 30px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 102px;

        .container {
            .inner {
                text-align: center;
                width: 100%;
                max-width: 1000px;
                margin: auto;
                .title {
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 74px;
                    color: #fff;
                    margin-bottom: 32px;
                }

                .para {
                    font-size: 22px;
                    margin-bottom: 32px;
                }
                .form-container {
                    color: #fff;
                    border-radius: 15px;
                    max-width: 600px;
                    margin: auto;
                    margin-top: 50px;
                    padding: 30px;
                    z-index: 1;
                    border: 1px solid #1d51fe;
                    background-color: rgba(29, 81, 254, 0.2);
                    -webkit-backdrop-filter: blur(5px);
                    backdrop-filter: blur(5px);
                    form {
                        text-align: center;

                        .wallet-info {
                            margin-top: 20px;
                        }

                        label {
                            display: block;
                            margin-bottom: 25px;
                            font-weight: 600;
                            font-size: 22px;
                            &.green {
                                color: #cddc39;
                            }
                            &.red {
                                color: #f44336;
                            }
                        }

                        .text-field {
                            width: 100%;
                            display: block;
                            font-size: 14px;
                            line-height: 1.42857143;
                            margin-bottom: 32px;
                            padding: 20px 32px;
                            border: 1px solid #0000;
                            border-radius: 10px;
                            background-color: #1f2337;
                            color: #a5a5a5;
                            background-color: #ffffff4d;
                            color: #fff;
                            outline: none;
                            &::placeholder {
                                color: rgba(255, 255, 255, 0.747);
                            }
                        }

                        .btn {
                            padding: 20px 40px;
                            border: 1px solid #1d51fe;
                            border-radius: 40px;
                            background-color: #1d51fe;
                            color: #fff;
                            -webkit-transition: 0.2s;
                            transition: 0.2s;
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}

#trailerModal {
    .modal-dialog {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        padding: 15px;

        .modal-content {
            background-color: transparent;

            .close {
                z-index: 9;
                position: absolute;
                top: 10px;
                right: 10px;
                color: #fff;
                opacity: 1;
                font-size: 35px;
            }

            .modal-body {
                padding: 0;
                padding-top: 100%;
                video,
                iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: none;
                }
            }
        }
    }
    &.in {
        display: flex !important;
        align-items: center;
    }
}

.dashboard-page {
    .contract-link {
        color: #ffffff;
        a:link {
            text-decoration: none;
        }

        a:visited {
            text-decoration: none;
        }

        a:hover {
            text-decoration: none;
        }

        a:active {
            text-decoration: none;
        }
    }
}
